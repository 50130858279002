// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-in-the-bag-js": () => import("./../../../src/pages/in-the-bag.js" /* webpackChunkName: "component---src-pages-in-the-bag-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tour-js": () => import("./../../../src/pages/tour.js" /* webpackChunkName: "component---src-pages-tour-js" */),
  "component---src-pages-wedding-js": () => import("./../../../src/pages/wedding.js" /* webpackChunkName: "component---src-pages-wedding-js" */),
  "component---src-pages-wedding-party-js": () => import("./../../../src/pages/wedding-party.js" /* webpackChunkName: "component---src-pages-wedding-party-js" */)
}

